<script setup lang="ts">
import {
  BaseCheckbox,
  BaseImageInput,
  BaseInput,
  BaseListbox,
  BaseRichText,
  BaseTextarea,
} from '#components'
// Fix clashes with enum used as both type and value
import {
  ContentBlockType,
  type ContentBlockType as ContentBlockTypeType,
} from '#imports'
import type { AnyObjectSchema } from 'yup'

const { open, pageId, position, initialBlock } = defineProps<{
  /**
   * Whether the modal is open.
   */
  open?: boolean
  /**
   * The page id that is currently being edited
   */
  pageId: id
  /**
   * Position of the block
   */
  position: number
  /**
   * The initial content block (for editing)
   */
  initialBlock?: ContentBlock
}>()

const emits = defineEmits<{
  (close: 'close'): void
  (event: 'saved', value: ContentBlock): void
}>()

const { t } = useI18n({ useScope: 'local' })

// NOTE: make sure this is not undefined initially, or the form validation will
// start acting weird. Bug reported: https://github.com/logaretm/vee-validate/issues/4624.
const contentBlockType = ref<ContentBlockType | undefined>(
  initialBlock?.blockType || ContentBlockType.Markdown,
)
// Instead, we reset the initial value to undefined as soon as the component is mounted.
// (only for the create form, not for update.)
onMounted(() => {
  if (initialBlock) return
  contentBlockType.value = undefined
})

const contentBlockTypes = Object.values(ContentBlockType)

// This is the new method! See Meedoen's component for how to use it.
// How it works is quite simple: we inject a ref with the schema into the
// tree. The child component can then set the value of this ref, which
// can basically be any schema. The schema validation and errors are handled here.
const injectedBlockSchema = ref<AnyObjectSchema | undefined>()
provide('blockFormSchema', injectedBlockSchema)

// Basically everything below is the legacy method
const legacyBlockSchema = computed(() => {
  if (!contentBlockType.value) return undefined
  return blockSchemaMap[contentBlockType.value]
})

const isLegacyBlockType = computed(() => !!legacyBlockSchema.value)

const blockSchema = computed(() => {
  if (!contentBlockType.value) return

  if (legacyBlockSchema.value) {
    return legacyBlockSchema.value
  }
  else if (injectedBlockSchema.value) {
    return injectedBlockSchema.value
  }
  else {
    // throw 'No schema found for this content block type.'
    return undefined
  }
})

watch(blockSchema, (schema) => {
  console.log(`blockSchema for ${contentBlockType.value}: `, schema)
})

interface FormFieldDefinition {
  label: string
  as: any // any type of component
  placeholder?: string
  items?: string[]
  // TODO: make this work
  // items?: { value: string; label: string }[]
}

// The field components to use for each field type.
const fieldDefinitions: Record<
  ContentBlockTypeType,
  Record<string, FormFieldDefinition>
> = {
  // this matches `ContentBlockType.Markdown`
  markdown: {
    body: {
      as: BaseRichText,
      label: 'Tekst',
      placeholder: 'Vul hier de tekst in...',
    },
  },
  image: {
    image: {
      as: BaseImageInput,
      label: 'Afbeelding',
    },
    altText: {
      as: BaseInput,
      label: 'Beschrijving van afbeelding (alt tekst voor Google)',
      placeholder: 'Bijv: Foto van vrijwilliger',
    },
    caption: {
      as: BaseInput,
      label: 'Onderschrift (optioneel)',
      placeholder: 'Bijv: vrijwilliger in actie!',
    },
  },
  image_text_buttons: {
    styling: {
      as: BaseListbox,
      label: 'Stijl',
      items: Object.values(ImageWithButtonsBlockStyles) /* .map((style) => ({
        value: style,
        label: style,
      })), */,
    },
    image: {
      as: BaseImageInput,
      label: 'Afbeelding',
    },
    isSwapped: {
      as: BaseCheckbox,
      label: 'Afbeelding rechts',
    },
    headingText: {
      as: BaseInput,
      label: 'Titel',
    },
    bodyText: {
      as: BaseTextarea,
      label: 'Tekst',
    },
    primaryButtonText: {
      as: BaseInput,
      label: 'Primaire knop tekst (optioneel)',
    },
    primaryButtonUrl: {
      as: BaseInput,
      label: 'Primaire knop bestemming (URL) (optioneel)',
    },
    secondaryButtonText: {
      as: BaseInput,
      label: 'Secundaire knop tekst (optioneel)',
    },
    secondaryButtonUrl: {
      as: BaseInput,
      label: 'Secundaire knop bestemming (URL) (optioneel)',
    },
  },
  four_cards: {
    mainActionLabel: {
      as: BaseInput,
      label: 'Toplink text',
      placeholder: 'Bijv: Bekijk alle klussen',
    },
    mainActionPath: {
      as: BaseInput,
      label: 'Toplink bestemming (pagina URL)',
      placeholder: 'Bijv: /vacaturebank',
    },
    image1: {
      as: BaseImageInput,
      label: 'Afbeelding 1',
    },
    title1: {
      as: BaseInput,
      label: 'Titel 1',
      placeholder: 'Vul hier de titel in...',
    },
    body1: {
      as: BaseTextarea,
      label: 'Tekst 1',
      placeholder: 'Vul hier de tekst in...',
    },
    buttonText1: {
      as: BaseInput,
      label: 'Knop tekst 1',
      placeholder: 'Bijv: Lees meer',
    },
    buttonUrl1: {
      as: BaseInput,
      label: 'Knop bestemming (URL) 1',
      placeholder: 'Bijv: /vacaturebank',
    },
    image2: {
      as: BaseImageInput,
      label: 'Afbeelding 2',
    },
    title2: {
      as: BaseInput,
      label: 'Titel 2',
      placeholder: 'Vul hier de titel in...',
    },
    body2: {
      as: BaseTextarea,
      label: 'Tekst 2',
      placeholder: 'Vul hier de tekst in...',
    },
    buttonText2: {
      as: BaseInput,
      label: 'Knop tekst 2',
      placeholder: 'Bijv: Lees meer',
    },
    buttonUrl2: {
      as: BaseInput,
      label: 'Knop bestemming (URL) 2',
      placeholder: 'Bijv: /vacaturebank',
    },
    image3: {
      as: BaseImageInput,
      label: 'Afbeelding 3',
    },
    title3: {
      as: BaseInput,
      label: 'Titel 3',
      placeholder: 'Vul hier de titel in...',
    },
    body3: {
      as: BaseTextarea,
      label: 'Tekst 3',
      placeholder: 'Vul hier de tekst in...',
    },
    buttonText3: {
      as: BaseInput,
      label: 'Knop tekst 3',
      placeholder: 'Bijv: Lees meer',
    },
    buttonUrl3: {
      as: BaseInput,
      label: 'Knop bestemming (URL) 3',
      placeholder: 'Bijv: /vacaturebank',
    },
    image4: {
      as: BaseImageInput,
      label: 'Afbeelding 4',
    },
    title4: {
      as: BaseInput,
      label: 'Titel 4',
      placeholder: 'Vul hier de titel in...',
    },
    body4: {
      as: BaseTextarea,
      label: 'Tekst 4',
      placeholder: 'Vul hier de tekst in...',
    },
    buttonText4: {
      as: BaseInput,
      label: 'Knop tekst 4',
      placeholder: 'Bijv: Lees meer',
    },
    buttonUrl4: {
      as: BaseInput,
      label: 'Knop bestemming (URL) 4',
      placeholder: 'Bijv: /vacaturebank',
    },
  },
  // TODO: get rid of this.
  dynamic_cards: {},
  youtube: {},
}

const blockSchemaFieldNames = computed(() => {
  if (!blockSchema.value) return {}
  console.log(`blockSchema.value`, blockSchema.value)
  return Object.keys((blockSchema.value as AnyObjectSchema).fields)
})

const getFormField = (fieldName: string) => {
  if (!contentBlockType.value) return

  const currentFormFields = fieldDefinitions[contentBlockType.value]

  if (!currentFormFields) {
    throw 'No fields found for this content block type.'
  }

  return currentFormFields[fieldName]
}

const getFormFieldComponent = (fieldName: string) => {
  console.log(`fieldName`, fieldName)
  const component = getFormField(fieldName)?.as
  if (!component) {
    throw `No component found for field: ${fieldName}.`
  }
  return component
}
const getFormFieldLabel = (fieldName: string) => {
  return getFormField(fieldName)?.label ?? fieldName
}
const getFormFieldPlaceholder = (fieldName: string) => {
  return getFormField(fieldName)?.placeholder
}
const getFormFieldItems = (fieldName: string) => {
  return getFormField(fieldName)?.items
}

// Type signature: const setFieldError: (field: never, message: string | string[] | undefined) => void
const formContext = useForm({
  validationSchema: blockSchema,
  initialValues: initialBlock?.data,
})

const { values, handleSubmit, isSubmitting, resetForm, errors, setFieldError }
  = formContext

// watch(
//   formContext,
//   (formContext) => {
//     console.log(`formContext`, formContext)
//   },
//   { immediate: true },
// )

// watch(errors, (errors) => {
//   console.log(`errors`, errors)
// })

const uploadFiles = async (values: Record<string, any>) => {
  // Function to recursively transform values
  const transformValues = async (obj: any) => {
    for (const key in obj) {
      if (obj[key] instanceof File) {
        console.log(`File found!`, obj[key])
        try {
          const { id, fileUrl } = await createContentFile({
            file: obj[key],
          })
          obj[key] = { id, fileUrl }
        }
        catch (error) {
          console.error(`Failed to upload the file for key "${key}":`, error)
          throw error
        }
      }
      else if (Array.isArray(obj[key])) {
        // If the value is an array, recursively transform each element
        for (let i = 0; i < obj[key].length; i++) {
          await transformValues(obj[key][i])
        }
      }
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        // If the value is an object, recursively transform it
        await transformValues(obj[key])
      }
      else {
        console.log(`No file found for key "${key}".`)
      }
    }
  }

  await transformValues(values)
  return values
}

// This is a bit of a hack to use the useServerErrorHandler while the blockSchemaMap is dynamic.
// I need to think about this. Maybe make it possible to pass in a ref/computed property?
const { handleServerError } = useServerErrorHandler(
  // TODO: need to fix this type error
  blockSchemaMap[ContentBlockType.FourCards],
  // TODO: type system complains about setFieldError having a `never` field.
  // @ts-ignore TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
  setFieldError,
)
const { handleValidationError } = useFormValidationErrorHandler()

const submit = handleSubmit(async (values) => {
  if (!blockSchema.value) {
    console.error(`No blockschema`)
    return
  }
  if (!contentBlockType.value) {
    console.error(`No content block type`)
    return
  }

  try {
    // Check if we have any new files in the payload.
    // If so, we need to upload them first.
    const payloadWithUploadedFiles = await uploadFiles(values)

    const payload: ContentBlockPayload = {
      pageId: pageId as number,
      blockType: contentBlockType.value,
      data: payloadWithUploadedFiles,
      position,
    }

    let savedBlock: ContentBlock | undefined
    if (!initialBlock) {
      savedBlock = await createContentBlock(payload)
    }
    else {
      savedBlock = await updateContentBlock(initialBlock.id, payload)
    }

    emits('close')
    emits('saved', savedBlock)
    resetForm()
  }
  catch (error) {
    console.error(`Catched error:`, error)
    // @ts-ignore TS2345: Argument of type 'string' is not assignable to parameter of type 'never'.
    handleServerError(error, blockSchema.value)
  }
}, handleValidationError)

const dev = process.dev
</script>

<template>
  <form :class="{ hidden: !open }" @submit.prevent="submit">
    <BaseModal
      :open="open"
      size="3xl"
      @close="$emit('close')"
    >
      <template v-if="!initialBlock" #title>
        {{ t('headingTitle') }}
      </template>
      <template
        v-else
        #title
      >
        {{ t('editHeadingTitle') }}
        <span v-if="dev">#{{ initialBlock.id }}</span>
      </template>

      <template v-if="!initialBlock">
        <BaseParagraph>
          {{
            initialBlock ? t('editHeadingText') : t('headingText')
          }}
        </BaseParagraph>

        <BaseSelect v-model="contentBlockType" class="mt-1">
          <option value="">
            Type blok
          </option>
          <option
            v-for="blockType in contentBlockTypes"
            :key="blockType"
            :value="blockType"
          >
            {{ $t(`content.blockType.${blockType}`) }}
          </option>
        </BaseSelect>
      </template>

      <div v-else>
        Blok type: {{ $t(`content.blockType.${initialBlock.blockType}`) }}
      </div>

      <div
        v-if="contentBlockType"
        class="bg-muted-50 mt-4 rounded-xl px-4 pb-4 pt-2"
      >
        <div v-if="contentBlockType == ContentBlockType.DynamicCards">
          <ContentBlockFormDynamicCards v-model="values" />
        </div>
        <template v-else-if="isLegacyBlockType">
          <div
            v-for="name in blockSchemaFieldNames"
            v-if="!!blockSchema"
            :key="name"
            class="mt-4"
          >
            <Field v-slot="{ field, errorMessage }" :name="name">
              <component
                :is="getFormFieldComponent(name)"
                v-bind="field"
                :error="errorMessage"
                :label="getFormFieldLabel(name)"
                :placeholder="getFormFieldPlaceholder(name)"
                :items="getFormFieldItems(name)"
                class=""
              />
            </Field>
          </div>
        </template>
        <div v-else>
          <ContentBlockFormRenderer :content-block-type="contentBlockType" />
        </div>
      </div>

      <template #buttons>
        <BaseButton @click="$emit('close')">
          {{ $t('cancel') }}
        </BaseButton>

        <SubmitButton :is-submitting="isSubmitting" @click="submit">
          {{
            $t('save')
          }}
        </SubmitButton>
      </template>
    </BaseModal>
  </form>
</template>

<i18n lang="json">
{
  "en": {
    "headingTitle": "Add a block",
    "editHeadingTitle": "Edit block",
    "headingText": "Choose the type of block you want to add:",
    "editHeadingText": "Add a new block"
  },
  "nl": {
    "headingTitle": "Blok toevoegen",
    "editHeadingTitle": "Blok bewerken",
    "headingText": "Kies het type blok dat je wilt toevoegen:",
    "editHeadingText": "Bewerk dit blok."
  }
}
</i18n>
